import React from "react";
import Layout from "../components/layout";
import { graphql } from "gatsby";
import styled from "styled-components";
import { StyledH1, StyledSectionWrapper } from "../theme/styles";
import t from "../theme/theme";

const StyleImg = styled.img`
  width: 100%;
`;

const StyledContent = styled.div`
  padding-top: 15vh;
  padding-right: ${t.spacing(2)};
  font-family: "dolly", serif;
`;

const PurchaseBtn = styled.a`
  display: inline-block;
  margin-top: ${t.spacing(2)};
  border: 1px solid ${t.text};
  padding: ${t.spacing(1)} ${t.spacing(2)};
  color: ${t.red}!important;
  text-transform: uppercase;
  font-weight: bold;
  font-family: "neuzeit", "Helvetica Neue", Helvetica, Arial, sans-serif;
`;

const shopifyUrl = `https://murmurer-art-store.myshopify.com/products/`;
const WorkPage = (props) => {
  const data = props.data.allShopifyProducts.edges[0].node;
  const imgSrc = data.images.edges[0].node.src;
  return (
    <Layout location={props.location}>
      <StyledSectionWrapper>
        <StyledH1>{data.title}</StyledH1>
        <StyledContent>
          <StyleImg src={imgSrc} alt={data.title} />
          <div dangerouslySetInnerHTML={{ __html: data.descriptionHtml }} />
          <PurchaseBtn href={`${shopifyUrl}${data.handle}`} target="_blank">
            Purchase
          </PurchaseBtn>
        </StyledContent>
      </StyledSectionWrapper>
    </Layout>
  );
};

export default WorkPage;

export const pageQuery = graphql`
  query ShopItemQuery($handle: String!) {
    allShopifyProducts(filter: { handle: { eq: $handle } }) {
      edges {
        node {
          title
          handle
          availableForSale
          descriptionHtml
          images {
            edges {
              node {
                src
              }
            }
          }
        }
      }
    }
  }
`;
